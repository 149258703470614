import { graphql, Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/services.scss";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import CommonSection from "../components/CommonSection";
import ClientLogoBanner from "../components/ClientLogoBanner";
import AccordionSection from "../components/AccordionSection";
import CaseStudy from "../sections/homepage/CaseStudy";
import Testimonials from "../sections/homepage/Testimonials";
import Blogs from "../sections/homepage/Blogs";
import ButtonBanner from "../components/ButtonBanner";

const BackgroundRemoval = ({ data }) => {
  const list = [
    {
      image: "../../images/bgremoval/360-car-visualizer.png",
      imageWebp: "../../images/bgremoval/360-car-visualizer.webp",
      title: "Bulk edit images",
      sub: "Convert your images into studio quality at scale.",
    },
    {
      image: "../../images/bgremoval/360-car-visualizer.png",
      imageWebp: "../../images/bgremoval/360-car-visualizer.webp",
      title: "Work at speed",
      sub: "Create Studio Quality Images in seconds on our platform.",
    },
    {
      image: "../../images/bgremoval/360-car-visualizer.png",
      imageWebp: "../../images/bgremoval/360-car-visualizer.webp",
      title: "Cloud storage",
      sub: "Upload and download thousands of images in second while maintaining your website speed.",
    },
    {
      image: "../../images/bgremoval/360-car-visualizer.png",
      imageWebp: "../../images/bgremoval/360-car-visualizer.webp",
      title: "AI driven intelligent edits",
      sub: "We use deep learning system to create high quality images",
    },
  ];

  const automated = [
    {
      image: "../../images/bgremoval/cars-360.png",
      imageWebp: "../../images/bgremoval/cars-360.webp",
      title: "Save money",
      sub: "A single platform that creates & manages your image gallery at a fraction of price.",
    },
    {
      image: "../../images/bgremoval/cars-360.png",
      imageWebp: "../../images/bgremoval/cars-360.webp",
      title: "Save time",
      sub: "Bulk editing feature coupled with cloud storage saves almost 60 - 70% of your time.",
    },
    {
      image: "../../images/bgremoval/cars-360.png",
      imageWebp: "../../images/bgremoval/cars-360.webp",
      title: "Gain users trust & transparency",
      sub: "Professional image gallery with 360 car view helps user trust sellers easily.",
    },
  ];

  return (
    <Layout>
      <Helmet>
        <title>
          HelloAR: Auto Image 360| Car Photo Edit| Background Removal
        </title>
        <meta
          name="description"
          content="Create auto image 360 & studio grade car photos with our AI driven backround removal software in a few seconds."
        />
        <meta
          name="keywords"
          content="360 car visualizer, cars 360, auto 360, auto image 360"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta
          property="og:image"
          content="../../videos/thumbnail/configurator.jpg"
        />
      </Helmet>

      <Container fluid className="services">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <CommonSection
          title="Professional gallery in minutes"
          sub="Unlock scale, growth, & conversions with our AI driven background removal software in a matter of seconds."
          buttonText="REQUEST A DEMO"
        >
          <VideoPlayer
            videoUrl="../../videos/car-studio-photography.mp4"
            videoThumbnail="../../images/thumbnail/background-removal.webp"
          />
        </CommonSection>
        <div className="spacer-sm"></div>
        <div className="container">
          <ClientLogoBanner />
          <div className="spacer"></div>
          <AccordionSection
            items={list}
            title="How does AI driven background removal software help?"
            mainIndex={3}
          />
          <div className="spacer"></div>
        </div>
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
        <div className="spacer"></div>
        <div className="container">
          <AccordionSection
            items={automated}
            title="Why automate background removal?"
            mainIndex={2}
          />
          <div className="spacer"></div>
          {/* <CaseStudy /> */}
          <div className="spacer"></div>
        </div>
        <Testimonials />
        {/* <div className="spacer"></div> */}
        {/* <Blogs /> */}
        {/* <div className="spacer"></div> */}
      </Container>
    </Layout>
  );
};

export default BackgroundRemoval;
